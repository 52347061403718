import React, { useEffect, useReducer, useRef } from 'react'
import { Search, Input, Label, Icon, Dropdown } from 'semantic-ui-react'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import _ from 'lodash'

import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { store } from '../../services/state'


const initialState = {
  loading: false,
  results: [],
  value: '',
  valueParent: null
}

const dropdownOptions = [
  { key: 'Username', text: 'Username', value: 'Username' },
  { key: 'API Key', text: 'API Key', value: 'API Key' }
]

function searchReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      let cleanStateUpdate = _.cloneDeep(initialState);
      return cleanStateUpdate
    case 'UPDATE_FILTER_CRITERIA':
      let filterCriteriaStateUpdate = { ...state, filterCriteria: action.filterCriteria }
      if (state.value !== '' && state.filterCriteria === 'Username') {
        // ------------------------------------------
        filterCriteriaStateUpdate.value = state.valueParent.description;
      }
      else if (state.value !== '' && state.filterCriteria === 'API Key') {
        // ------------------------------------------
        filterCriteriaStateUpdate.value = state.valueParent.title;
      }
      return filterCriteriaStateUpdate
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query, valueParent: null, copied: false }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results, valueParent: null, copied: false }
    case 'UPDATE_SELECTION':
      let selectionStateUpdate = { ...state, valueParent: action.parent, copied: false }
      if (state.filterCriteria === 'Username') {
        // ------------------------------------------
        selectionStateUpdate.value = action.parent.title;
      }
      else if (state.filterCriteria === 'API Key') {
        // ------------------------------------------
        selectionStateUpdate.value = action.parent.description;
      }
      return selectionStateUpdate
    case 'COPY_SELECTION':
      return { ...state, copied: true }
    default:
      throw new Error('Unexpected action has taken place')
  }
}

export const AdminDashboard = observer(() => {
  const [state, dispatch] = useReducer(searchReducer,
    {
      ...initialState,
      filterCriteria: 'Username'
    })
  const { loading, results, value, valueParent } = state
  const searchTargets = store.user.map((item, idx) => ({
    id: `${item.Username}-${idx}`,
    title: item.Username,
    description: item.APIKey
  }))
  const timeoutRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const handleSearchChange = (e, data) => {
    // -----------------------------
    dispatch({ type: 'START_SEARCH', query: data.value })

    timeoutRef.current = setTimeout(() => {
      const re = new RegExp(_.escapeRegExp(data.value), 'i')
      const isMatch = (result) => {
        if (state.filterCriteria === 'Username') { return re.test(result.title) }
        else { return re.test(result.description) }
      }

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(searchTargets, isMatch).map(result => ({ ...result, key: result.id })),
      })
    }, 300)
  }

  const handleResultSelect = (e, data) => {
    // -----------------------------
    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title, parent: data.result })
  }

  const onApiKeyCopy = (e) => {
    dispatch({ type: 'COPY_SELECTION', action: null })
  }

  const onFilterChange = (e, data) => {
    dispatch({ type: 'UPDATE_FILTER_CRITERIA', filterCriteria: data.value })
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ padding: '3.5em 2em' }}>
        <span style={{ fontSize: '5vw' }}>{`${store.user.length}`}</span>
        <span style={{ fontSize: '1vw' }}>Users</span>
      </div>
      <div style={{ padding: '3.5em 0.5em' }}>
        <Search
          loading={loading}
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          results={results}
          value={value}
          input={<Input
            placeholder='Search by username or API Key'
            label={<Dropdown defaultValue={state.filterCriteria} options={dropdownOptions} onChange={onFilterChange} />}
            labelPosition='right'
          />}
        />
      </div>
      <div style={{ padding: '3.5em 120px' }}>
        {(valueParent) &&
          <CopyToClipboard text={(state.filterCriteria === 'Username') ? valueParent.description : valueParent.title} onCopy={onApiKeyCopy} >
            <Label as='a' color='teal' onClick={onApiKeyCopy} image>
              <Icon name='copy outline' />
              <span style={{ maxWidth: '400px' }}>{(state.filterCriteria === 'Username') ? valueParent.description : valueParent.title}</span>
            </Label>
          </CopyToClipboard>
        }
      </div>
    </div>
  )
})