// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'

import { isAdmin, isAuthenticated, logout, getLoginRedirectUrl } from '../services/self'

import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from '../services/get-fragments'

// components
import Register from './Register'

// DOC Custom Styles
import './NavBar.css';

export const EnvironmentNavBar = (props) => {

  return props.env !== 'prod' ? 
  (
    <Menu.Item style={{ color: "deepskyblue", fontSize: "30px" }}>{props.env}</Menu.Item>
  )
  :
  null;
}

export const NavBarApiCatalogLink = (props) => {

  return isAuthenticated() ?
  (
    <Menu.Item as={Link} to="/apis">{props.text}</Menu.Item>
  )
  :
  null;
}

export const NavBar = observer(
  class NavBar extends React.Component {
    getCognitoUrl = (type) => {
      let redirectUri = getLoginRedirectUrl();
      return `${window.location.protocol}//${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
    }

    insertAuthMenu() {
      return isAuthenticated() ?
        (
          <Menu.Menu position="right">
            {isAdmin() && <Menu.Item as={Link} to="/admin">Admin Panel</Menu.Item>}
            <Menu.Item key="dashboard" as={Link} to="/dashboard">My Dashboard</Menu.Item>
            <Menu.Item key="signout" as="a" onClick={logout}>Sign Out</Menu.Item>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            <Menu.Item key="signin" as="a"
                       href={this.getCognitoUrl('login')}>
                Sign In
            </Menu.Item>
            <Register />
          </Menu.Menu>
        )
    }

    render() {
      return <Menu inverted borderless attached style={{ flex: "0 0 auto" }} >
        <Menu.Item as={Link} to="/">
          <Image src="/custom-content/DOC_logo_horizontal_RGB_White_Text-SVG.svg" style={{ paddingRight: "10px" }} />
        </Menu.Item>
        <Menu.Item as={Link} to="/getting-started">{fragments.GettingStarted.title}</Menu.Item>
        <NavBarApiCatalogLink text={fragments.APIs.title} />
        <Menu.Item as={Link} to="/news">{fragments.News.title}</Menu.Item>
        <Menu.Item as={Link} to="/terms-of-use">{fragments.TermsOfUse.title}</Menu.Item>
        <EnvironmentNavBar env={fragments.Home.env} />

        {this.insertAuthMenu()}
      </Menu >
    }
  }
)

export default NavBar
