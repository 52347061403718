// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'

// services
import { store } from './state'
import { updateAllUserData } from './api-catalog'
import { initApiGatewayClient, apiGatewayClient, cognitoDomain, cognitoIdentityPoolId, cognitoUserPoolId, cognitoClientId, cognitoRegion } from './api'
import * as jwt_decode from "jwt-decode";

export function isAuthenticated() {
  return store.idToken
}

export function isAdmin() {
  return store.idToken &&
    `${jwt_decode(store.idToken)['cognito:preferred_role']}`.includes('-CognitoAdminRole-')
}

export function init() {
  initApiGatewayClient() // init a blank client (will get overwritten if we have creds)

  // attempt to refresh credentials from active session
  let idToken
  let parsedToken
  let username;
  let valid = false

  try {
    idToken = localStorage.getItem(cognitoUserPoolId)
    if (idToken) { // this `if` prevents console.error spam
      parsedToken = jwt_decode(idToken)
      valid = parsedToken.exp * 1000 > new Date()
      username = (valid && ('email' in parsedToken)) ? parsedToken['email'] : ''
    }
  } catch (error) {
    console.error(error)
  }

  if (valid && username !== '') {
    store.idToken = idToken
    store.username = username
    setCredentials(username)
  } else {
    logout()
  }
}

export function login() {
  return new Promise((resolve, reject) => {

    let idToken, accessToken, username, migration_status, old_cognito_identity

    try {
      window.location.hash
        .replace(/^#/, '')
        .split('&')
        .map(param => param.split('='))
        .forEach(param => {
          // record the id_token and access_token
          if (param[0] === 'id_token') idToken = param[1]
          if (param[0] === 'access_token') accessToken = param[1]

        })

      if (idToken) { // we get both, we set both, but we only really care about the idToken
        var id_decoded = jwt_decode(idToken);
        username = ('email' in id_decoded) ? id_decoded['email'] : null;
        migration_status = ('custom:migration_status' in id_decoded) ? id_decoded['custom:migration_status'] : null;
        old_cognito_identity = ('custom:old_cognito_identity' in id_decoded) ? id_decoded['custom:old_cognito_identity'] : null;
        var postMigration = !(migration_status && migration_status === 'PENDING_IDENTITY_MIGRATION');

        localStorage.setItem(cognitoUserPoolId, idToken)

        store.idToken = idToken
        store.username = username

        setCredentials(username, postMigration)
          .then(() => {
            // i.e. if migration is not complete, do remaining migration tasks!
            if (!postMigration) {
              var body = {
                'username': username,
                'user_pool_id': cognitoUserPoolId,
                'old_cognito_identity': old_cognito_identity
              }
              apiGatewayClient()
                .then(apiGatewayClient => apiGatewayClient.post('/migrate', {}, body, {}))
                .then(res => {
                  // MIGRATION TASKS NOW COMPLETE
                  // -> Reset credentials as though this is a normal login from this point
                  postMigration = true;
                  setCredentials(username, postMigration)
                })  
                .then(res => {
                  return resolve(idToken);
                })
                .catch(error => {
                  console.error('[ERROR] User migration unsuccessful');
                  reject('[ERROR] User migration unsuccessful please contact API Support');
                });
            }
            // Otherwise, if post migration, resolve as a normal login attempt
            resolve(idToken)
          })
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getLoginRedirectUrl = () => `${window.location.protocol}//${window.location.host}/index.html?action=login`
export const getLogoutRedirectUrl = () => `${window.location.protocol}//${window.location.host}/index.html?action=logout`

function setCredentials(username, postMigration = true) {
  console.log('initializing AWS credentials...')

  let preferred_role = jwt_decode(store.idToken)['cognito:preferred_role']
  let params = {
    IdentityPoolId: cognitoIdentityPoolId,
    Logins: {
      [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]: store.idToken
    }
  }

  username = username ? username : null;

  if (preferred_role)
    params.RoleArn = preferred_role

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh((error) => {
      if (error) {
        console.error(error)
        return reject(error)
      }

      initApiGatewayClient(AWS.config.credentials)

      if (postMigration) {
        return apiGatewayClient()
          .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, { 'username': username }, {}))
          .then(res => {
            return updateAllUserData()
          })
          .then(res => {
            resolve();
          })
      } else {
        return resolve();
      }
    })
  })
}

export function logout() {
  if (store.idToken) {
    store.resetUserData()
    localStorage.clear()

    if (cognitoDomain) {
      // redirect to cognito to log out there, too
      const redirectUrl = getLogoutRedirectUrl()
      window.location = `https://${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${redirectUrl}`
    }
  }
}
