// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

// semantic-ui
import { Container, Header, Icon, Image, Button } from 'semantic-ui-react'

// services
import { isAuthenticated } from '../services/self'
import { updateUsagePlansAndApisList, getApi, subscribe, unsubscribe } from '../services/api-catalog';

// components
import ApisMenu from '../components/ApisMenu'
// Currently the SwaggerLayoutPlugin cannot be applied as it is not fully supported in swagger-ui-react

// state
import { store } from '../services/state.js'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

export default observer(class ApisPage extends React.Component {
  componentDidMount() { this.updateApi().then(() => updateUsagePlansAndApisList(true)) }
  componentDidUpdate() { this.updateApi() }

  updateApi = () => {
    return getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, this.props.match.params.usagePlanName)
  }

  render() {
    let errorHeader
    let errorBody

    let spec = typeof store.api !== 'undefined' ? toJS(store.api) : undefined;

    if (store.apiList.loaded) {
      if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
        errorHeader = `No APIs Published`
        errorBody = `Your administrator hasn't added any APIs to your account. Please contact them to publish an API.`
      } else if (!store.api) {
        errorHeader = `No Such API`
        errorBody = `The selected API doesn't exist.`
      }
    }

    return (
      <div style={{ display: "flex", flex: "1 1 auto", overflow: "hidden" }}>
        <ApisMenu path={this.props.match} />
        <div className="swagger-section" style={{ flex: "1 1 auto", overflow: 'auto' }}>
          {typeof store.api !== 'undefined' && (
            <div style={{display: 'flex', flexDirection: "row", justifyContent: "left", alignItems: "center"}}>
              <Image style={{ paddingTop: "30px", paddingBottom: "25px", paddingLeft: "50px", width: "220px" }} size='small' src={store.api.logo} />
              <SubscriptionButtons />
            </div>
          )}
          
          <div className="swagger-ui-wrap" id="swagger-ui-container" style={{ padding: "0 25px"}}>
            {typeof store.api !== 'undefined' && <SwaggerUI spec={ spec.swagger } docExpansion={'list'} onComplete={(swaggerUi) => { swaggerUi.preauthorizeApiKey('api_key', store.apiKey); }}/> }
            {errorHeader && errorBody && (
              <React.Fragment>
                <Header as='h2' icon textAlign="center" style={{ padding: "40px 0px" }}>
                  <Icon name='warning sign' circular />
                  <Header.Content>{errorHeader}</Header.Content>
                </Header>
                <Container text textAlign='justified'>
                  <p>{errorBody}</p>
                </Container>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
})

const SubscriptionButtons = observer(class SubscriptionButtons extends React.Component {
  state = {}

  render() {
    const { api, username } = store
    return (
      (api && isAuthenticated()) ? !api.generic ? (
        api.subscribed ? (
          <Button basic color='black' style={{ height: "50px", marginLeft: "25px"}} onClick={() => unsubscribe(api.usagePlan.id)}>Unsubscribe</Button>
        ) : (
          <Button color='green' style={{ backgroundColor: "#21BA45", height: "50px", marginLeft: "25px"}} onClick={() => subscribe(api.usagePlan.id, username)} >Subscribe</Button>
        )
      ) : <Header as='h4' color='grey'>This API is not configured for subscription from the portal.</Header> : null
    )
  }
})
