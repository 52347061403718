// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

import { isAdmin, isAuthenticated, logout } from '../services/self'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from '../services/get-fragments'

// react-router
import { Link } from 'react-router-dom'

// semantic-ui
import { Header, Segment, Container, Image, Button, Popup, Icon } from 'semantic-ui-react'

export const EnvironmentHeader = (props) => {

  return props.env !== 'prod' ? 
  (
    <p style={{ color: "deepskyblue", fontSize: "30px" }}>{props.env}</p>
  )
  :
  null;
}

export const ApiCatalogLink = (props) => {

  return isAuthenticated() ?
  (
    <Link to="/apis" style={{ padding: "0.78571429em 1.5em 0.78571429em", color: "white" }}>{fragments.Home.apiListButton}</Link>
  )
  :
  null;
}

export const HomePage = observer(() => (
  <React.Fragment>
      <Segment vertical textAlign='center' style={{ color: "whitesmoke", backgroundColor: "whitesmoke", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url(https://www.doc.govt.nz/thumbs/hero/contentassets/f4a19ca6143845febdd628e3cea2e183/hump-ridge-lookout.jpg)", padding: "40px 0px", margin: "0px !important" }}>
        <div style={{ display: "inline-flex", flexDirection: "column", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "15px", padding: "15px"}}>
          <Popup
            trigger={ <Icon name='camera' size='large' style={{ alignSelf: "flex-end" }} /> }
            position='right center'
            basic
            inverted
          >
            <Header as='h4'>About this image</Header>
            <p>
              {fragments.Home.imageTitle}
            </p>
            <p>
              Image: {fragments.Home.imageAttribution} | ©
            </p>
          </Popup>
          <Image centered size='small' src='/custom-content/DOC_logo_shield_only_RGB_White_Text-SVG.svg' />
          <Header as='h1' style={{ color: "whitesmoke", fontFamily: "'Zilla Slab','Courier New',monospace", fontSize: "280%", fontWeight: "400" }}>{fragments.Home.header}</Header>
          <EnvironmentHeader env={fragments.Home.env} />
          <p>{fragments.Home.tagline}</p>
          <div style={{ display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center' }}>
            <Link to="/getting-started"><Button positive>{fragments.Home.gettingStartedButton}</Button></Link>
            <ApiCatalogLink />
          </div>
        </div>
      </Segment>

    <Segment vertical style={{ padding: "40px 0px", margin: "0 !important" }}>
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Segment>
  </React.Fragment>
))

export default HomePage
